import { FC, Fragment, useState } from "react"
import { IPublicCategoryResponse } from "src/types/commonTypes"
import CategoryCards from "src/views/components/Cards/CategoryCards"
import CompanyCards from "src/views/components/Cards/CompanyCards"
import VacancyCards from "src/views/components/Cards/VacancyCards"
import HrDashboard from "src/views/components/HrDashboard/HrDashboard"
import Carousel from "./Carousel"
import { Dialog, Transition } from "@headlessui/react"
import {
  DialogBody,
  DialogContaier,
  DialogPosition
} from "src/views/components/SearchboxFilterModal/SearchboxFilterModal.styles"
import VacancyMobileContent from "../../vacancy/MobileVacancyContent/VacancyMobileContent"
import { getSingleVacancy } from "src/server/queryHooks/useVacancy"
import { IVacancy } from "src/types/vacancyTypes"
import { useTranslation } from "next-i18next"
import BlogCards from "src/views/components/Cards/BlogCards"
import dynamic from "next/dynamic"
const CVMakerBanner = dynamic(() => import("src/views/components/CVMaker/CVMakerBanner"), { ssr: false })

interface Props {
  mainPageData: any
  publicCategories: IPublicCategoryResponse[]
  topCompanies: any
}

const LandingGenerator: FC<Props> = ({ mainPageData, publicCategories, topCompanies }) => {
  const { t } = useTranslation("common")

  const vipVacancies = mainPageData?.vip_vacancies

  const [currentVacancy, setCurrentVacancy] = useState<IVacancy>()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openVacancy = (event: any) => {
    getVacancy(event)
  }

  const getVacancy = async (vacancy: any) => {
    const vacancyData: IVacancy = await getSingleVacancy(Number(vacancy.id))

    setCurrentVacancy(vacancyData)
    setIsModalOpen(true)
  }

  return (
    <div>
      {publicCategories && <CategoryCards data={publicCategories} />}

      <Carousel title={t("VIP_VACANCY")} icon={"/images/orange_rocket_launcher.svg"}>
        <VacancyCards openVacancy={openVacancy} data={vipVacancies || []} />
      </Carousel>

      <CVMakerBanner />

      <Carousel title={t("BEST_COMPANIES")} icon={"/images/purple_account_balance.svg"}>
        <CompanyCards data={topCompanies || []} />
      </Carousel>

      <HrDashboard />

      <Carousel title={t("URG_EMPLOYMENT")} icon={"/images/Rectangle 11.svg"}>
        <VacancyCards
          openVacancy={openVacancy}
          data={mainPageData.urgently_hiring_vacancies ? mainPageData.urgently_hiring_vacancies : []}
        />
      </Carousel>
      <Carousel title={t("BLOG")} icon={"/images/blog.svg"}>
        <BlogCards/>
      </Carousel>
      {window.innerWidth < 736 && currentVacancy ? (
        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => setIsModalOpen(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <DialogPosition className="detailed-filter-wrapper h-auto">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="ease-in duration-500"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="detailed-filter h-auto" as={DialogContaier}>
                  <Dialog.Description
                    as={DialogBody}
                    style={{ height: "100vh" }}
                    className={"styled-scrollbar overflow-hidden px-0 py-0"}
                  >
                    <VacancyMobileContent
                      openSimilarVacancy={() => {}}
                      onClose={() => setIsModalOpen(false)}
                      hidden={false}
                      vacancy={currentVacancy}
                      isLoading={false}
                    />
                  </Dialog.Description>
                </Dialog.Panel>
              </Transition.Child>
            </DialogPosition>
          </Dialog>
        </Transition>
      ) : (
        <></>
      )}
    </div>
  )
}

export default LandingGenerator
