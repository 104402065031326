import { BlogBody, BlogCardBox, BlogHeader } from "src/views/components/Cards/Card.styles"
import Typography from "../typography"
import { SwiperSlide } from "swiper/react"

import { useTranslation } from "next-i18next"
import { cx } from "class-variance-authority"

const blogs = [
  {
    type: "ტექნოლოგია",
    description: "სტაჟირების მნიშვნელობა კარიერული განვითარებისთვის",
    url: "https://blog.myjobs.ge/%e1%83%a1%e1%83%a2%e1%83%90%e1%83%9f%e1%83%98%e1%83%a0%e1%83%94%e1%83%91%e1%83%98%e1%83%a1-%e1%83%9b%e1%83%9c%e1%83%98%e1%83%a8%e1%83%95%e1%83%9c%e1%83%94%e1%83%9a%e1%83%9d%e1%83%91%e1%83%90-%e1%83%99/",
    image: "https://blog.myjobs.ge/wp-content/uploads/2024/11/შიდა-ფოტო-1-1160x772.png"
  },
  {
    type: "ტექნოლოგია",
    description: "5 ყველაზე მოთხოვნადი პროფესია მსოფლიოში",
    url: "https://blog.myjobs.ge/5-%e1%83%a7%e1%83%95%e1%83%94%e1%83%9a%e1%83%90%e1%83%96%e1%83%94-%e1%83%9b%e1%83%9d%e1%83%97%e1%83%ae%e1%83%9d%e1%83%95%e1%83%9c%e1%83%90%e1%83%93%e1%83%98-%e1%83%9e%e1%83%a0%e1%83%9d%e1%83%a4/",
    image: "https://blog.myjobs.ge/wp-content/uploads/2024/11/შიდა-ფოტო-1-copy-1160x772.png"
  },
  {
    type: "ტექნოლოგია",
    description: "როგორ შევარჩიოთ პროფესია სწორად",
    url: "https://blog.myjobs.ge/%e1%83%a0%e1%83%9d%e1%83%92%e1%83%9d%e1%83%a0-%e1%83%a8%e1%83%94%e1%83%95%e1%83%90%e1%83%a0%e1%83%a9%e1%83%98%e1%83%9d%e1%83%97-%e1%83%9e%e1%83%a0%e1%83%9d%e1%83%a4%e1%83%94%e1%83%a1%e1%83%98%e1%83%90/",
    image:
      "https://blog.myjobs.ge/wp-content/uploads/2024/11/%E1%83%A8%E1%83%98%E1%83%93%E1%83%90-%E1%83%A4%E1%83%9D%E1%83%A2%E1%83%9D.jpg"
  }
]

export const BlogCard = ({ blog }: any) => {
  const { t } = useTranslation("common")

  return (
    <>
      <BlogCardBox>
        <BlogHeader>
          <img
            style={{ borderRadius: "16px 16px 0px 0px", height: "154px", width: "100%", objectFit: "cover" }}
            src={blog.image}
            alt="blog"
          />
        </BlogHeader>
        <BlogBody>
          <div className="h-[92px]">
            <Typography className="text-secondary-50" type="text">
              {blog.type}
            </Typography>
            <Typography type="h5" weight="bold" className="line-clamp-3 h-19 leading-relaxed">
              {blog.description}
            </Typography>
          </div>
          <Typography
            type="text"
            className={cx("mr-auto min-w-24 cursor-pointer pt-1 text-left text-primary-100 underline")}
          >
            <a href={blog.url} className="cursor-pointer" target="_blank">
              {t("MORE_SHOW")}
            </a>
          </Typography>
        </BlogBody>
      </BlogCardBox>
    </>
  )
}

const BlogCards = () => {
  return (
    <>
      {blogs.map((blog, index) => (
        <SwiperSlide className="top-vacancy-box" key={index}>
          <BlogCard blog={blog} />
        </SwiperSlide>
      ))}
    </>
  )
}
BlogCards.displayName = "SwiperSlide"
export default BlogCards
