export type FieldsToCheck = {
  about_me: string
  work_experience: string
  education: string
  skills: string
  soft_skills: string
  certificates: string
  trainings: string
}

export const checkEmptyFields = (data: any) => {
  const fieldsToCheck: FieldsToCheck = {
    about_me: "ABOUT_ME",
    work_experience: "WORK_EXPERIENCE",
    education: "EDUCATION",
    skills: "PROFESSIONAL_TRAITS",
    soft_skills: "PERSONAL_TRAITS",
    certificates: "CERTIFICATES",
    trainings: "TRAININGS"
  }

  const emptyFields: Partial<FieldsToCheck> = {}

  ;(Object.keys(fieldsToCheck) as Array<keyof FieldsToCheck>).forEach(key => {
    if (data?.[key] === null || data?.[key].length < 1) {
      emptyFields[key] = fieldsToCheck[key]
    }
  })

  return emptyFields
}
